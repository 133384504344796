import React from 'react';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import WriteBlog from './components/blog/WriteBlog';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BlogDetail from './components/blog/BlogDetails';
import Redirect from './components/Redirect';

function App() {
  return (
    // <Router>
    //   <div className="App bg-darkBackground">
    //     <NavBar />
    //     <Routes>
    //       <Route path="/" element={<HomePage />} />
    //       <Route path="/blog/:id" element={<BlogDetail />} />
    //       <Route path="/writeblog" element={<WriteBlog />} />
    //     </Routes>
    //     <Footer />
    //   </div>
    // </Router>
    <div className="App">
      <Redirect />
    </div>
  );
}

export default App;
