import React from 'react';

const NavBar = () => {
  return (
    <nav className="bg-transparent text-pearl py-4 shadow-lg">
      <div className="max-w-7xl flex flex-wrap justify-between items-center text-white px-4 lg:pl-36">
          <a 
            href="/" className="text-3xl font-bold transition-all duration-300 bg-gradient-to-r from-golden to-yellow-600 bg-clip-text text-transparent">
          Axhat Sinha
        </a>
        <ul className="flex flex-row gap-x-8">
          <li>
            <a 
              href="#home" 
              className="transition-all duration-300 text-gray-200"
            >
              Home
            </a>
          </li>
          <li>
            <a 
              href="#about" 
              className="transition-all duration-300 text-gray-200"
            >
              About
            </a>
          </li>
          <li>
            <a 
              href="#footer" 
              className="transition-all duration-300 text-gray-200"
            >
              Contact me
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
