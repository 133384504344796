// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC94vVneqHKd0l3lgfP7Hxx555nJSmAj3Y",
  authDomain: "axhatsinha.firebaseapp.com",
  projectId: "axhatsinha",
  storageBucket: "axhatsinha.appspot.com",
  messagingSenderId: "405826821150",
  appId: "1:405826821150:web:9565f8cdb893edd36d220f",
  measurementId: "G-4BQWJ6LRBF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initializing auth for gyms
const auth = getAuth(app);
const dbGym = getFirestore(app)
const storage = getStorage(app); // Initialize Firebase Storage

// Initialize Firestore for the secondary app
const db = getFirestore(app);

export { auth, analytics, db, dbGym, storage };