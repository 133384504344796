import React, { useEffect } from 'react';

const Redirect = () => {
    useEffect(() => {
        // Redirect to the desired URL
        window.location.href = 'https://eecd.in';
    }, []);

    return (
        <div>
            <p>Redirecting to <a href="https://eecd.in/diamondcitycuttackbhubaneswar">Diamond City, Bhubaneswar</a>...</p>
        </div>
    );
};

export default Redirect;
