import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

function BlogDetail() {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const blogRef = doc(db, 'blogs', id);
                const blogSnap = await getDoc(blogRef);

                if (blogSnap.exists()) {
                    setBlog(blogSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching blog: ", error);
            }
        };

        fetchBlog();
    }, [id]);

    if (!blog) return <div>Loading...</div>;

    return (
        <div className="p-4 lg:p-8 lg:px-36">
            <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-golden to-yellow-600 bg-clip-text text-transparent">{blog.title}</h2>
            {blog.images[0] && <img src={blog.images[0]} alt={blog.title} className="w-full h-[500px] object-cover mb-4 rounded-xl" />}
            <div className="text-gray-200" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>
    );
}

export default BlogDetail;
